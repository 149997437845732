.left-menu {
  background-color: $lightgrey;
  height: 100vh;
  width: $widthleftmenu;
  position: fixed;
  padding-left: 50px;
  padding-top: 50px;
  z-index: 1010;

  .left-menu-item {
    margin-top: 30px;

    > .nav {
      > .nav-item {
        position: relative;
        padding: 10px 0;

        > .nav-link {
           padding-left: 0;

          &:hover {
            span {
              text-decoration: underline;
            }
          }

          &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            z-index: 1;
            left: -50px;
            right: 0;
          }

          &.active {
            &:before {
              background: $blue;
            }

            > span {
               font-weight: bold;
               color: white;
            }

            + .product-submenu {
               background: #e9ecef;
               margin-left: -50px;
               padding: 20px 0 20px 50px;
               position: relative;
               bottom: -10px;
               z-index: 100;

               .nav {
                  > .nav-item {
                     > .nav-link {
                        padding-left: 0;
                        margin-left: 20px;

                        &.active {
                           font-weight: bold;
                        }
                     }
                  }
               }
            }
          }

          > span {
            position: relative;
            z-index: 2;
            color: $blue;
          }
        }
      }
    }
  }
}
