body#index {
   #actions {
      button {
         display: flex;
         justify-content: center;
         align-items: center;
         margin-top: 40px;
   
         > i {
            margin-left: 5px;
   
            &.rotate180 {
               transform: rotateY(180deg);
            }
         }
      }
   }
}
