.pallet_form_layout {
    .colorpicker-container {
        cursor: pointer;
        display: inline-block;
        background-size: 2rem;
        background-repeat: no-repeat;
        margin-left: 0.5rem;
        position: relative;

        > input {
            border: 0;
            margin-left: 2rem;
            // padding-left: 30px;
            cursor: pointer;
        }
    }

    .form-group-flex {
        display: flex;
    }

    .colors-container {
        display: flex;
        flex-direction: column;

        .color {
            display: flex;
            align-items: flex-end;
            position: relative;
            margin-bottom: 1rem;

            &:first-child {
                .form-group, .form-group-inline {
                    label {
                        display: block;
                    }
                }

                span.need-to-create {
                    top: 35px;
                }
            }

            .form-group, .form-group-inline {
                display: flex;
                flex-direction: row;
                margin-right: 1rem;
                margin-bottom: 0;

                label {
                    margin-bottom: 0;
                    display: none;
                }
            }

            .form-group {
                flex-direction: column;
            }

            .form-group-inline {
                align-items: center;
            }

            button.remove {
                color: $red;
            }

            span.need-to-create {
                position: absolute;
                right: -10px;
                top: 10px;
            }
        }
    }

    .pallet-actions {
        display: flex;

        .btn {
            margin-right: 15px;
        }
    }

    .picker_wrapper.popup, 
    .picker_wrapper.popup .picker_arrow::before, 
    .picker_wrapper.popup .picker_arrow::after {
        background-color: white;
        box-shadow: 0 0 3px 0px rgba(0,0,0,0.4);
    }

    .picker_wrapper.popup .picker_arrow::after {
        box-shadow: none;
    }

    .popup.popup_right {
        top: -5px;
        left: calc(100% + 20px);
    }
}