
body#security {
   display: flex;
   justify-content: center;
   align-items: center;
   height: 100vh;
   background: rgba(0, 0, 0, 0) linear-gradient(135deg, #45484d 0%, #000000 100%) repeat scroll 0 0;

   form.login-form {
      padding: 50px 50px 30px;
      border-radius: 10px;
      color: white;
      background-color: #35537a;

      .switch {
         input[type="checkbox"] {
            + label {
               color: white;
            }

            &:checked + label {
               color: white;
            }
         }
      }
   }
}
