.overlay {
  form {
    position: relative;

    > .img-thumbnail {
      display: block;
      margin: 0 auto;
    }

    .overlay-actions {
      display: none;

      &, & > .backdrop {
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100%;
      }
    }

    &:hover > .overlay-actions {
      display: flex;
      justify-content: center;
      align-items: center;

      > .backdrop {
        background: white;
        opacity: 0.5;
        z-index: 10;
      }

      button, label {
        position: relative;
        z-index: 20;
      }
    }
  }
}
