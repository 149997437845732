body#report {
   .all-good-container {
      display: flex;
      justify-content: center;
      margin-top: 5rem;

      .all-good {
         display: flex;
         flex-direction: column;
         align-items: center;

         > i {
            font-size: 6rem;
         }

         > span {
            font-size: 2rem;
            margin-top: 30px;
         }
      }

   }
   .reports {
      .report {
         &:not(:last-of-type) {
            border-bottom: 1px solid $lightgrey;
            padding-bottom: 50px;
            margin-bottom: 50px;
         }

         .report-title {
            margin-bottom: 30px;
         }
      }
   }
}
