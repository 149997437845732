header {
	background-color: $blue;
	position: fixed;
	width: 100%;
	height: $heightheader;
	z-index: 1000;

	nav {
		width: calc(100% - (#{$containerleftpadding} + #{$containerrightpadding}));
		height: 100%;
		margin: 0 auto;
		padding: 20px 0;
		display: flex;
		justify-content: space-between;

		.user {
			display: inline-flex;
			align-items: center;
			color: white;
			font-size: 20px;

			a {
				color: inherit;

				&.logout {
					display: inline-flex;
					align-items: center;
					margin-left: 15px;
				}

				&:hover {
					color: $lightgrey;
					text-decoration: none;
				}
			}
		}

		a {
			> img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}
