.body-layout {
  padding-top: $heightheader;

  .content {
    padding-left: $widthleftmenu;
    position: relative;

    > .loader {
      display: none;
      position: absolute;
      top: 0;
      left: $widthleftmenu;
      right: 0;
      bottom: 0;
      background: white;
      z-index: 990;
      opacity: 0;
      transition: opacity 0.2s ease;

      &.loading {
        display: block;
        opacity: 0.5;

        + .progress {
          position: absolute;
          z-index: 1010;
          width: calc(90% - #{$widthleftmenu});
          left: $widthleftmenu;
          right: 0;
          top: 0;
          bottom: 0;
          height: 16px;
          margin: auto;
        }
      }
    }

    .sublayout {
      padding: $containerpadding;
      position: relative;

      .container {
         margin-left: 0;
         padding-left: 0;
         padding-right: 0;
      }

      a.get-back {
        position: absolute;
        top: 0; left: 0;
        display: flex;
        align-items: flex-end;

        i {
          font-size: 20px;
        }
      }
    }
  }
}

body.login header {
  position: unset;
}
